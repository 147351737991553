import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CardList from '../components/CardList'
import Card from '../components/Card'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const Index = ({ data, pageContext }) => {
    const posts = data.allContentfulKiwimPost.edges
    const featuredPost = posts[0].node
    const { currentPage } = pageContext
    const isFirstPage = currentPage === 1

    return (
        <Layout>
            <SEO />
            {!isFirstPage && (
                <Helmet>
                    <title>{`${config.siteTitle} - Page ${currentPage}`}</title>
                </Helmet>
            )}
            <Container>
                {isFirstPage ? (
                    <CardList>
                        <Card {...featuredPost} featured />
                        {posts.slice(1).map(({ node: post }) => (
                            <Card key={post.id} {...post} />
                        ))}
                    </CardList>
                ) : (
                        <CardList>
                            {posts.map(({ node: post }) => (
                                <Card key={post.id} {...post} />
                            ))}
                        </CardList>
                    )}
            </Container>
            <Pagination context={pageContext} />
        </Layout>
    )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulKiwimPost(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              timeToRead
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
  }
`

export default Index